/* eslint-disable max-len */
import React, {useEffect} from 'react';
import {useStore} from 'effector-react';
import migrated from '../globals/migrated';
import formatNumber from '../lib/helpers/bn-format';
import translations from '../lib/translations';
import ProgressCircular from './storybook/progress-circular';

export default function MigrationProgress(props) {
    let initialBalance = migrated.gen2circulatingSupply,
        balance = useStore(migrated.$migrated),
        locales = props.langInfo && props.langInfo.locales,
        currentBalanceParagraph,
        percentage,
        translate = translations.init(props);

    useEffect(() => {
        migrated.on.startPolling();
        return migrated.on.stopPolling;
    }, []);

    if (balance) {
        percentage = (balance / initialBalance) * 100;
        currentBalanceParagraph = (
            <p>{formatNumber(locales, balance, 0, true, false, 2)} NRG</p>
        );
    }

    return (
        <div className="progress">
            <p>{translate.t('OVERALL_MIGRATION_PROGRESS', true)}</p>
            <div className="progress-details">
                <div className="values">
                    <div className="value">
                        <small>{translate.t('INITIAL_MIGRATION_CONTRACT_BALANCE', true)}:</small>
                        <p>{formatNumber(locales, initialBalance, 0, true, false, 2)} NRG</p>
                    </div>
                    <div className="value">
                        <small>{translate.t('CURRENT_MIGRATION_BALANCE', true)}:</small>
                        {currentBalanceParagraph}
                    </div>
                    <div className="value chart">
                        <small>{translate.t('CURRENT_MIGRATION_PROGRESS', true)}:</small>
                        <ProgressCircular progress={percentage || 0} stroke={15} text={`${Math.round(percentage * 100) / 100}%`} />
                    </div>
                </div>
            </div>
        </div>
    );
}
