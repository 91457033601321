import Web3 from 'web3';
import energiExtension from '@energi/web3-ext';
import {createStore} from 'effector';
import networkInfo from '../lib/constants/network-info';
import blockPolling from './block-polling';
/* ********************************************************************************************************** */
/* ********************************************************************************************************** */

const $lastBlock = blockPolling.$lastBlock;

const gen2circulatingSupply = Web3.utils.toBN('26961880001785450000000000');

let polling = false,
    web3;

const $migrated = createStore(0);
const on = {
    startPolling: async () => {
        const migrated = await getMigrated();
        polling = true;
        $migrated.setState(migrated);
    },
    stopPolling: () => {
        polling = false;
    }
};
web3 = new Web3(networkInfo.networkAddress);
energiExtension.extend(web3);

const getMigrated = async () => {
    // new block
    const remaining = await web3.eth.getBalance(networkInfo.smartContractAddress);
    // substract the initial balance from the remaining to get the currently migrated value
    return gen2circulatingSupply.sub(Web3.utils.toBN(remaining));
};

/* ********************************************************************************************************** */
/* ********************************************************************************************************** */

$lastBlock.watch(async () => {
    let migrated;
    // new block
    if (polling) {
        migrated = await getMigrated();
        $migrated.setState(migrated);
    }
});

/* ********************************************************************************************************** */
/* ********************************************************************************************************** */

/**
* @property masternodeListing
*     @property {String} claimed the total amount that currently is claimed
*     @property {Object} masternodeListing.on
*         @property {Function} masternodeListing.on.submitTx sets $txOwnerAddress and initiates the polling process
*         @property {Function} masternodeListing.on.clear erases $txOwnerAddress and halts the polling process
* @since 0.4.0
*/
const masternodeOperationTx = {
    $migrated,
    gen2circulatingSupply,
    on
};

export default masternodeOperationTx;
